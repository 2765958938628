import { useState } from 'react';
import { Button } from '@material-ui/core';

export default function ButtonBase(props) {
    const [disabled, setDisabled] = useState(false);

    const debouncedOnClick = () => {
        const runClick = async () => {
            setDisabled(true);
            const shouldReEnable = await props.onClick(); //returning anything means error
            if (!props.oneTime || shouldReEnable) {
                setTimeout(() => {
                    setDisabled(false);
                }, 800);
            }
        };
        runClick();
    };

    return (
        <Button
            disabled={disabled}
            aria-disabled={disabled}
            variant={props.variant}
            className={props.className}
            onClick={debouncedOnClick}
            size={props.size}
        >
            {props.children}
        </Button>
    );
}




